import ActionType from '@/Common/Enum/ActionType.js';
import nightcrawler, {
  NIGHTCRAWLER_CHECK_STATUS,
  onlyFailing
} from '@/Common/Classifiers/NightcrawlerCheckClassifier.js';

export default nightcrawler({
  _root: [
    {
      status: NIGHTCRAWLER_CHECK_STATUS.ERROR,
      actionType: ActionType.ACTION_REQUIRED,
      errorMessages: ({ checks }) =>
        onlyFailing(checks).map((c) => {
          switch (c.msg_code) {
            case 'DMARC001':
              return 'Only one CNAME or TXT record can exist.';
            case 'DMARC002':
              return 'More than one DMARC entry exist in TXT record.';
            case 'DMARC003':
              return 'The DMARC record is formatted incorrectly.';
            case 'DMARC004':
              return "The DMARC record does not contain required directives: 'v','p'.";
            case 'DMARC005':
            default:
              return c.msg;
          }
        })
    }
  ],
  v: [
    {
      default: true,
      required: true,
      actionType: ActionType.ACTION_REQUIRED,
      message: `Not specified (Should be 'DMARC1')`,
      errorMessages: ['First directive is not v=DMARC1.']
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.ERROR,
      actionType: ActionType.ACTION_REQUIRED,
      errorMessages: ['First directive is not v=DMARC1.']
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION
    }
  ],
  p: [
    {
      default: true,
      required: true,
      actionType: ActionType.ACTION_REQUIRED,
      message: `Not specified (Should be one of the following 'none', 'quarantine' or 'reject')`
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.ERROR,
      actionType: ActionType.ACTION_REQUIRED,
      errorMessages: ["Policy should be 'none', 'quarantine' or 'reject'."]
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      value: ['none', 'quarantine', 'reject'],
      actionType: ActionType.NO_ACTION
    }
  ],
  sp: [
    {
      default: true,
      required: false,
      actionType: ActionType.NO_ACTION_WITH_DEFAULT,
      message:
        "Not specified (Value of 'p' applied, can be 'none', 'quarantine', 'reject')"
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.ERROR,
      actionType: ActionType.ACTION_REQUIRED,
      errorMessages: ["Policy should be 'none', 'quarantine' or 'reject'."]
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      value: ['none', 'quarantine', 'reject'],
      actionType: ActionType.NO_ACTION
    }
  ],
  pct: [
    {
      default: true,
      required: false,
      message: '100',
      actionType: ActionType.NO_ACTION_WITH_DEFAULT
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.ERROR,
      actionType: ActionType.ACTION_REQUIRED,
      errorMessages: ['Policy Percentage is not an integer from 0 to 100.']
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION
    }
  ],
  rua: [
    {
      default: true,
      required: false,
      message: "Not specified (Value usually 'mailto:report@example.com')",
      actionType: ActionType.ACTION_RECOMMENDED
    },
    {
      status: [NIGHTCRAWLER_CHECK_STATUS.WARN, NIGHTCRAWLER_CHECK_STATUS.ERROR],
      actionType: ({ status }) =>
        ({
          [NIGHTCRAWLER_CHECK_STATUS.WARN]: ActionType.ACTION_RECOMMENDED,
          [NIGHTCRAWLER_CHECK_STATUS.ERROR]: ActionType.ACTION_REQUIRED
        })[status],
      errorMessages: ({ checks }) =>
        onlyFailing(checks).map(({ msg_code, msg }) => {
          switch (msg_code) {
            case 'DMARCRUA01':
              return 'Aggregate Reporting Addresses should be a comma separated list of addresses.';
            case 'DMARCRUA02':
              return 'Delivery method is unknown.';
            case 'DMARCRUA03':
              return 'Email addresses are invalid.';
            case 'DMARCRUA04':
              return 'Report size specifiers are invalid.';
            case 'DMARCRUA05':
              return 'Report domains have invalid MX records.';
            case 'DMARCRUA06':
              return 'External Domains in your DMARC have not given you permission for your reports to be sent to them.';
            default:
              return msg;
          }
        })
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION
    }
  ],
  ruf: [
    {
      default: true,
      required: false,
      message: "Not specified (Value usually 'mailto:report@example.com')",
      actionType: ActionType.NO_ACTION_WITH_DEFAULT
    },
    {
      status: [NIGHTCRAWLER_CHECK_STATUS.WARN, NIGHTCRAWLER_CHECK_STATUS.ERROR],
      actionType: ({ status }) =>
        ({
          [NIGHTCRAWLER_CHECK_STATUS.WARN]: ActionType.ACTION_RECOMMENDED,
          [NIGHTCRAWLER_CHECK_STATUS.ERROR]: ActionType.ACTION_REQUIRED
        })[status],
      errorMessages: ({ checks }) =>
        onlyFailing(checks).map(({ msg_code, msg }) => {
          switch (msg_code) {
            case 'DMARCRUF01':
              return 'Failure Reporting Addresses should be a comma separated list of addresses.';
            case 'DMARCRUF02':
              return 'Delivery method is unknown.';
            case 'DMARCRUF03':
              return 'Email addresses are invalid.';
            case 'DMARCRUF04':
              return 'Report size specifiers are invalid.';
            case 'DMARCRUF05':
              return 'Report domains have invalid MX records.';
            case 'DMARCRUF06':
              return 'External Domains in your DMARC have not given you permission for your reports to be sent to them.';
            default:
              return msg;
          }
        })
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION
    }
  ],
  fo: [
    {
      default: true,
      required: false,
      message:
        "0: Generate a DMARC failure report if all underlying authentication mechanisms fail to produce an aligned 'pass' result",
      actionType: ActionType.NO_ACTION_WITH_DEFAULT
    },
    {
      status: [NIGHTCRAWLER_CHECK_STATUS.WARN, NIGHTCRAWLER_CHECK_STATUS.ERROR],
      actionType: ({ status }) =>
        ({
          [NIGHTCRAWLER_CHECK_STATUS.WARN]: ActionType.ACTION_RECOMMENDED,
          [NIGHTCRAWLER_CHECK_STATUS.ERROR]: ActionType.ACTION_REQUIRED
        })[status],
      errorMessages: ({ checks }) =>
        onlyFailing(checks).map((c) => {
          switch (c.msg_code) {
            case 'DMARCFO01':
              return 'Failure Reporting Options is not a colon delimited string.';
            case 'DMARCFO02':
            default:
              return c.msg;
          }
        })
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION,
      message: ({ value }) => {
        return value
          .split(':')
          .map((d) => {
            switch (d) {
              case '0':
                return '0: Generate a DMARC failure report if all underlying authentication mechanisms fail to produce an aligned "pass" result.';
              case '1':
                return '1: Generate a DMARC failure report if any underlying authentication mechanism produced something other than an aligned "pass" result.';
              case 'd':
                return 'd: Generate a DKIM failure report if the message had a signature that failed evaluation, regardless of its alignment.';
              case 's':
                return 's: Generate an SPF failure report if the message failed SPF evaluation, regardless of its alignment.';
            }
          })
          .join('\n');
      }
    }
  ],
  rf: [
    {
      default: true,
      required: false,
      message:
        "Not specified (Value usually 'afrf' for 'Authentication Failure Reporting Format')",
      actionType: ActionType.NO_ACTION_WITH_DEFAULT
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION,
      errorMessages: ["Failure Reporting Format should be 'afrf'."]
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.ERROR,
      message: ({ value }) => `'${value}' is an invalid reporting format`,
      actionType: ActionType.ACTION_REQUIRED
    }
  ],
  ri: [
    {
      default: true,
      required: false,
      message: '86400: Number of seconds between aggregate reports',
      actionType: ActionType.NO_ACTION_WITH_DEFAULT
    },
    {
      status: [NIGHTCRAWLER_CHECK_STATUS.WARN, NIGHTCRAWLER_CHECK_STATUS.ERROR],
      actionType: ({ status }) =>
        ({
          [NIGHTCRAWLER_CHECK_STATUS.WARN]: ActionType.ACTION_RECOMMENDED,
          [NIGHTCRAWLER_CHECK_STATUS.ERROR]: ActionType.ACTION_REQUIRED
        })[status],
      errorMessages: ({ checks }) =>
        onlyFailing(checks).map((c) => {
          switch (c.msg_code) {
            case 'DMARCRI01':
              return 'Aggregate Reporting Interval is not a valid positive integer.';
            case 'DMARCRI02':
              return 'Aggregate Reporting Interval will be accommodated on a best-effort basis.';
            default:
              return c.msg;
          }
        })
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION
    }
  ],
  aspf: [
    {
      value: null,
      default: true,
      required: false,
      message: 'r: relaxed',
      actionType: ActionType.NO_ACTION_WITH_DEFAULT
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION,
      errorMessages: ['Value is not one of "r" or "s".']
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.ERROR,
      actionType: ActionType.ACTION_REQUIRED
    }
  ],
  adkim: [
    {
      default: true,
      required: false,
      message: 'r: relaxed',
      actionType: ActionType.NO_ACTION_WITH_DEFAULT
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION,
      errorMessages: ['Value is not one of "r" or "s".']
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.ERROR,
      actionType: ActionType.ACTION_REQUIRED
    }
  ]
});
