<script setup>
import SecondaryButton from '@/Components/Button/SecondaryButton.vue';

const redirect = () => {
  window.open('https://signup.sendmarc.com', '_blank', 'noopener,noreferrer');
};
</script>

<template>
  <SecondaryButton size="large" @click="redirect">Sign Up</SecondaryButton>
</template>
