<script setup>
import SiteLayout from '@/Layouts/Layout.vue';
import Card from '@/Components/Card/Card.vue';
import { inject, provide, reactive } from 'vue';
import PrimaryButton from '@/Components/Button/PrimaryButton.vue';
import RiskLabel from '@/Pages/DomainScore/Partials/RiskLabel.vue';
import RiskDescription from '@/Pages/DomainScore/Partials/RiskDescription.vue';
import CircularScore from '@/Components/Chart/CircularScore.vue';
import RiskPill from '@/Pages/Common/RiskPill.vue';
import RatingCard from '@/Pages/DomainScore/Partials/RatingCard.vue';
import IconExpandCircleDown from '@/Components/Icons/IconExpandCircleDown.vue';
import DetailedReport from '@/Pages/DomainScore/Partials/DetailedReport.vue';
import FlaredScoreCard from '@/Pages/DomainScore/Partials/FlaredScoreCard.vue';
import { useForm } from '@inertiajs/vue3';
import Alert from '@/Components/Alert.vue';
import { isValidDomain } from '@/Common/IsValidDomain.js';
import Pill from '@/Components/Pill.vue';
import DomainInput from '@/Pages/Common/DomainInput.vue';
import ACTION_TYPES from '@/Common/Enum/ActionType.js';
import PartnerLogo from '@/Components/Logo/PartnerLogo.vue';

const props = defineProps({
  score: Object,
  domain: String,
  partnerLogo: {
    type: [Object, null]
  }
});

const layout = inject('layout');

const state = reactive({
  showDetailedReport: layout === 'pdf',
  domainName: props.domain,
  error: null
});

const form = useForm({});

provide('domain', props.domain);
provide('score', props.score);

const scoreDomain = () => {
  state.error = null;

  if (!isValidDomain(state.domainName)) {
    state.error = 'Enter a Valid Domain Name';
    return;
  }
  form.get(route('score.show', state.domainName));
};
</script>

<template>
  <SiteLayout :title="'Domain Score for ' + domain">
    <template #headerRight>
      <PartnerLogo v-if="partnerLogo" :partner-logo="partnerLogo" />
    </template>
    <Card v-if="layout === 'web'">
      <form @submit.prevent="scoreDomain">
        <div
          class="flex flex-col items-start justify-between gap-4 md:flex-row md:items-center"
        >
          <DomainInput
            v-model="state.domainName"
            required
            redirect-to="score.show"
            :error-message="state.error"
            class="flex w-full flex-1"
          />
          <div>
            <PrimaryButton
              size="large"
              class="mt-2"
              :is-loading="form.processing"
            >
              Get Your Domain Score
            </PrimaryButton>
          </div>
        </div>
        <Alert
          v-if="score.hasOwnProperty('detail')"
          class="mt-10"
          title="Record not found"
          variant="danger"
        >
          {{ score.detail }}
        </Alert>
      </form>
    </Card>

    <FlaredScoreCard
      v-if="score.hasOwnProperty('score_pct')"
      :score="score.score_pct"
    >
      <div class="relative z-10">
        <div class="mb-2 text-xl">
          Domain Score for
          <strong>{{ domain }}</strong>
        </div>
        <div class="grid grid-cols-3 gap-4 lg:grid-cols-6">
          <div class="col-span-2 flex flex-col">
            <RiskLabel
              class="text-2xl md:text-3xl"
              :score="score.score_pct"
              :use-rating-to-calculate="false"
            >
              Risk
            </RiskLabel>
            <RiskDescription :score="score.score_pct" />
            <div class="my-5 flex gap-2">
              <Pill :variant="score.records?.ns?.score ? 'success' : 'default'">
                NS
              </Pill>
              <Pill :variant="score.records?.mx?.score ? 'success' : 'default'">
                MX
              </Pill>
              <Pill :variant="score.records?.a?.score ? 'success' : 'default'">
                A
              </Pill>
            </div>

            <div v-if="layout === 'web'" class="flex gap-1">
              <IconExpandCircleDown
                class="shrink-0 transform cursor-pointer text-nowrap transition-transform duration-300 ease-in-out"
                :class="{ 'rotate-180': state.showDetailedReport }"
              />
              <a
                class="cursor-pointer underline"
                @click="state.showDetailedReport = !state.showDetailedReport"
              >
                View Detailed Report
              </a>
            </div>
          </div>

          <div class="flex flex-col items-center">
            <div class="mb-1 pt-0 font-bold md:mb-2 md:block md:pt-5">
              Overall Score
            </div>
            <CircularScore class="max-w-[180px]" :score="score.score_pct" />
          </div>

          <RatingCard
            title="Impersonation"
            lookup="impersonation"
            :score="score"
            :pills="{ dmarc: 'DMARC', spf: 'SPF' }"
          >
            <RiskPill
              tag="DKIM"
              :score="score.records['dkim'].score"
              :variant="
                (score.records['dkim'].num_dkim_records === 0 &&
                  ACTION_TYPES.INFO_DEFAULT) ||
                null
              "
            />
          </RatingCard>
          <RatingCard
            title="Privacy"
            lookup="privacy"
            :score="score"
            :pills="{ tlsrpt: 'TLS-RPT', mtasts: 'MTA-STS' }"
          />
          <RatingCard
            title="Branding"
            lookup="marketing"
            :score="score"
            :pills="{ bimi: 'BIMI' }"
          >
            <RiskPill
              :score="score.records.bimi.has_certificate ? 100 : 0"
              tag="Certificate"
            />
            <RiskPill
              :score="score.records.bimi.has_valid_logo ? 100 : 0"
              tag="Image"
            />
          </RatingCard>
        </div>
      </div>

      <DetailedReport v-if="state.showDetailedReport" />
    </FlaredScoreCard>
  </SiteLayout>
</template>
