import ActionType, { ACTION_TYPES } from '@/Common/Enum/ActionType.js';
import cyclopsTagMap from '@/Common/Classifiers/CyclopsTagMapper.js';

const BASE = {
  errors: {
    pill: 'Record contains errors',
    actionType: ActionType.ACTION_REQUIRED
  },
  warnings: {
    pill: 'Record contains warnings',
    actionType: ActionType.ACTION_RECOMMENDED
  },
  'No record': {
    pill: 'Record not Found',
    actionType: ActionType.ACTION_REQUIRED
  },
  'Too many records': {
    pill: 'Multiple records',
    actionType: ActionType.ACTION_REQUIRED
  }
};

export const DMARC = cyclopsTagMap({
  ...BASE,
  'p=none': {
    pill: 'Policy on None',
    actionType: ActionType.ACTION_REQUIRED
  },
  'p=quarantine': {
    pill: 'Policy on Quarantine',
    actionType: ActionType.ACTION_REQUIRED
  },
  'p=reject': {
    pill: 'Policy on Reject',
    actionType: ActionType.NO_ACTION
  },
  'sp=none': {
    pill: 'Sub-domain Policy on None',
    actionType: ActionType.ACTION_REQUIRED
  },
  'sp=quarantine': {
    pill: 'Sub-domain Policy on Quarantine',
    actionType: ActionType.ACTION_REQUIRED
  },
  'sp=reject': {
    pill: 'Sub-domain Policy on Reject',
    actionType: ActionType.NO_ACTION
  },
  'pct=0': {
    pill: 'Policy not Enforced',
    actionType: ActionType.ACTION_REQUIRED
  },
  'pct<100': {
    pill: 'Policy not Fully Enforced',
    actionType: ActionType.ACTION_REQUIRED
  },
  'pct=100': {
    pill: 'Policy Fully Enforced',
    actionType: ActionType.NO_ACTION
  },
  'Too many records': {
    pill: 'Multiple DMARC Records',
    actionType: ActionType.ACTION_REQUIRED
  },
  'rua=[]': {
    pill: 'No Reporting Addresses',
    actionType: ActionType.ACTION_REQUIRED
  },
  'ruf=[]': {
    pill: 'No Failure Reporting Addresses',
    actionType: ActionType.INFO
  },
  'rua=[*]': {
    pill: 'Reporting Address Specified',
    actionType: ActionType.NO_ACTION
  },
  'ruf=[*]': {
    pill: null,
    actionType: ActionType.NO_ACTION
  }
});

export const SPF = cyclopsTagMap({
  ...BASE,
  'Too many records': {
    pill: 'Multiple SPF Records',
    actionType: ActionType.ACTION_REQUIRED
  },
  'no spf no mx': {
    pill: 'No SPF, No MX',
    actionType: ActionType.INFO
  },
  recursive: {
    pill: 'Recursive Lookup',
    actionType: ActionType.ACTION_REQUIRED
  },
  errors: {
    pill: 'Record Contains Errors',
    actionType: ActionType.ACTION_REQUIRED
  },
  'dns>10': {
    pill: 'Too Many DNS Lookups',
    actionType: ActionType.ACTION_REQUIRED
  },
  'dns>6': {
    pill: 'High DNS Lookups',
    actionType: ActionType.ACTION_RECOMMENDED
  },
  'void>2': {
    pill: 'Too Many VOID Lookups',
    actionType: ActionType.ACTION_REQUIRED
  },
  'void>0': {
    pill: 'High VOID Lookups',
    actionType: ActionType.ACTION_RECOMMENDED
  },
  'mxa>10': {
    pill: 'Too Many MX A Records',
    actionType: ActionType.ACTION_REQUIRED
  },
  'no all': {
    pill: '"All" Missing',
    actionType: ActionType.ACTION_REQUIRED
  },
  '~all': {
    pill: '"All" Soft Fail',
    actionType: ActionType.NO_ACTION
  },
  '-all': {
    pill: '"All" Fail',
    actionType: ActionType.NO_ACTION
  },
  '?all': {
    pill: '"All" Neutral',
    actionType: ActionType.ACTION_REQUIRED
  },
  '+all': {
    pill: '"All" Pass',
    actionType: ActionType.ACTION_REQUIRED
  }
});

export const DKIM = cyclopsTagMap({
  ...BASE,
  'no dkim no mx': {
    pill: 'No DKIM, No MX',
    actionType: ActionType.INFO
  },
  'no keys': {
    pill: 'No Known Keys',
    actionType: ActionType.ACTION_RECOMMENDED
  },
  'invalid keys': {
    pill: 'Invalid Keys',
    actionType: ActionType.ACTION_RECOMMENDED
  },
  'error keys': {
    pill: 'Errors Found',
    actionType: ActionType.ACTION_RECOMMENDED
  },
  'large keys': {
    pill: 'Large Keys',
    actionType: ActionType.INFO
  },
  'weak keys': {
    pill: 'Weak Keys',
    actionType: ActionType.ACTION_RECOMMENDED
  },
  'no errors': {
    pill: 'Record Found',
    actionType: ActionType.NO_ACTION
  },
  'dkim wildcard': {
    pill: 'Wildcard Record Exists',
    actionType: ActionType.ACTION_RECOMMENDED
  },
  'No record': {
    pill: 'No Known Keys',
    actionType: ActionType.ACTION_OPTIONAL
  }
});

export const BIMI = cyclopsTagMap({
  ...BASE,
  'Too many records': {
    pill: 'Multiple BIMI Records',
    actionType: ActionType.ACTION_REQUIRED
  },
  'valid logo': {
    pill: 'Valid Image',
    actionType: ActionType.NO_ACTION
  },
  'invalid logo': {
    pill: 'Image Errors',
    actionType: ActionType.ACTION_REQUIRED
  },
  'valid signature': {
    pill: 'Valid Certificate',
    actionType: ActionType.NO_ACTION
  },
  'invalid signature': {
    pill: 'Certificate Errors',
    actionType: ActionType.ACTION_REQUIRED
  },
  'no signature': {
    pill: 'No Certificate',
    actionType: ActionType.ACTION_REQUIRED
  }
});

export const TLSRTP = cyclopsTagMap({
  ...BASE,
  'Too many records': {
    pill: 'Multiple TLS-RTP Records',
    actionType: ActionType.ACTION_REQUIRED
  },
  'http rua': {
    pill: 'Malformed Rua',
    actionType: ACTION_TYPES.INFO
  },
  errors: {
    pill: 'Record Contains Errors',
    actionType: ActionType.ACTION_REQUIRED
  }
});

export const MTASTS = cyclopsTagMap({
  ...BASE,
  'Too many records': {
    pill: 'Multiple MTA-STS Records',
    actionType: ActionType.ACTION_REQUIRED
  },
  'no policy': {
    pill: 'No Policy',
    actionType: ActionType.ACTION_REQUIRED
  },
  'policy enforce': {
    pill: 'Policy Enforce',
    actionType: ActionType.NO_ACTION
  },
  'policy none': {
    pill: 'Policy None',
    actionType: ActionType.ACTION_REQUIRED
  },
  'policy testing': {
    pill: 'Policy Testing',
    actionType: ActionType.ACTION_REQUIRED
  },
  'maxage<day': {
    pill: 'Expiry <1day',
    actionType: ActionType.INFO
  },
  errors: {
    pill: 'Record Contains Errors',
    actionType: ActionType.ACTION_REQUIRED
  }
});
