import ActionType from '@/Common/Enum/ActionType.js';
import nightcrawler, {
  NIGHTCRAWLER_CHECK_STATUS,
  onlyFailing
} from '@/Common/Classifiers/NightcrawlerCheckClassifier.js';

export default nightcrawler({
  _root: [
    {
      status: [NIGHTCRAWLER_CHECK_STATUS.WARN, NIGHTCRAWLER_CHECK_STATUS.ERROR],
      actionType: ActionType.ACTION_REQUIRED,
      errorMessages: ({ checks }) =>
        onlyFailing(checks).map((c) => {
          switch (c.msg_code) {
            case 'SPF001':
              return 'There is more than one SPF entry in the TXT record.';
            case 'SPF002':
              return 'The SPF record is formatted incorrectly.';
            case 'SPF003':
              return 'The SPF record length is not safe for UDP DNS transport.';
            case 'SPF004':
              return "The SPF record does not contain required directives: 'v'";
            case 'SPF005':
            case 'SPF012':
            case 'SPF013':
            default:
              return c.msg;
          }
        })
    }
  ],
  v: [
    {
      default: true,
      required: true,
      actionType: ActionType.ACTION_REQUIRED,
      message: `Not specified (Should be 'spf1')`
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.ERROR,
      actionType: ActionType.ACTION_REQUIRED,
      errorMessages: ['First directive should be v=spf1.']
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION
    }
  ],
  a: [
    {
      status: [NIGHTCRAWLER_CHECK_STATUS.WARN, NIGHTCRAWLER_CHECK_STATUS.ERROR],
      actionType: ({ status }) =>
        ({
          [NIGHTCRAWLER_CHECK_STATUS.WARN]: ActionType.ACTION_RECOMMENDED,
          [NIGHTCRAWLER_CHECK_STATUS.ERROR]: ActionType.ACTION_REQUIRED
        })[status],
      errorMessages: ({ checks }) =>
        onlyFailing(checks).map((c) => {
          switch (c.msg_code) {
            case 'SPFA01':
              return 'Invalid domain.';
            case 'SPFA02':
              return 'The A record does not exist.';
          }
        })
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION
    }
  ],
  mx: [
    {
      status: [NIGHTCRAWLER_CHECK_STATUS.WARN, NIGHTCRAWLER_CHECK_STATUS.ERROR],
      actionType: ({ status }) =>
        ({
          [NIGHTCRAWLER_CHECK_STATUS.WARN]: ActionType.ACTION_RECOMMENDED,
          [NIGHTCRAWLER_CHECK_STATUS.ERROR]: ActionType.ACTION_REQUIRED
        })[status],
      errorMessages: ({ checks }) =>
        onlyFailing(checks).map((c) => {
          switch (c.msg_code) {
            case 'SPFMX01':
              return 'Invalid domain.';
            case 'SPFMX02':
              return 'MX record does not exist.';
            case 'SPFMX03':
              return 'MX record points to invalid mail servers.';
            case 'SPFMX04':
              return 'MX record mail servers A records does not exist.';
          }
        })
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION
    }
  ],
  include: [
    {
      status: [NIGHTCRAWLER_CHECK_STATUS.WARN, NIGHTCRAWLER_CHECK_STATUS.ERROR],
      actionType: ({ status }) =>
        ({
          [NIGHTCRAWLER_CHECK_STATUS.WARN]: ActionType.ACTION_RECOMMENDED,
          [NIGHTCRAWLER_CHECK_STATUS.ERROR]: ActionType.ACTION_REQUIRED
        })[status],
      errorMessages: ({ checks }) =>
        onlyFailing(checks).map((c) => {
          switch (c.msg_code) {
            case 'SPFINCLUDE01':
              return 'Include is a invalid domain or macro.';
            case 'SPFINCLUDE02':
              return (
                'Include record does not exist: ' +
                c.msg.replace('Include record exists', '')
              );
            case 'SPFINCLUDE03':
              return (
                'Include macro is invalid: ' +
                c.msg.replace('Include macro is valid', '')
              );
          }
        })
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION
    }
  ],
  redirect: [
    {
      status: [NIGHTCRAWLER_CHECK_STATUS.WARN, NIGHTCRAWLER_CHECK_STATUS.ERROR],
      actionType: ({ status }) =>
        ({
          [NIGHTCRAWLER_CHECK_STATUS.WARN]: ActionType.ACTION_RECOMMENDED,
          [NIGHTCRAWLER_CHECK_STATUS.ERROR]: ActionType.ACTION_REQUIRED
        })[status],
      errorMessages: ({ checks }) =>
        onlyFailing(checks).map((c) => {
          switch (c.msg_code) {
            case 'SPFREDIRECT01':
              return "No directives allowed after 'all' or 'redirect'.";
            case 'SPFREDIRECT02':
              return "Separator should be '='.";
            case 'SPFREDIRECT03':
              return 'Redirect has a invalid domain.';
            case 'SPFREDIRECT04':
              return (
                'Redirect record does not exist: ' +
                c.msg.replace('Redirect record exists', '')
              );
          }
        })
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION
    }
  ],
  exists: [
    {
      status: [NIGHTCRAWLER_CHECK_STATUS.WARN, NIGHTCRAWLER_CHECK_STATUS.ERROR],
      actionType: ({ status }) =>
        ({
          [NIGHTCRAWLER_CHECK_STATUS.WARN]: ActionType.ACTION_RECOMMENDED,
          [NIGHTCRAWLER_CHECK_STATUS.ERROR]: ActionType.ACTION_REQUIRED
        })[status],
      errorMessages: ({ checks }) =>
        onlyFailing(checks).map((c) => {
          switch (c.msg_code) {
            case 'SPFEXISTS01':
              return 'Exists is not a macro.';
            case 'SPFEXISTS02':
              return (
                'Exists macro is invalid: ' +
                c.msg.replace('Exists macro is valid', '')
              );
            case 'SPFEXISTS03':
              return "Exists without a macro can behave like '+all'.";
          }
        })
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION
    }
  ],
  ip4: [
    {
      status: NIGHTCRAWLER_CHECK_STATUS.ERROR,
      actionType: ActionType.ACTION_REQUIRED,
      errorMessages: ['Invalid IPv4 IP/Prefix.']
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION
    }
  ],
  ip6: [
    {
      status: NIGHTCRAWLER_CHECK_STATUS.ERROR,
      actionType: ActionType.ACTION_REQUIRED,
      errorMessages: ['Invalid IPv6 IP/Prefix.']
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION
    }
  ],
  exp: [
    {
      status: [NIGHTCRAWLER_CHECK_STATUS.WARN, NIGHTCRAWLER_CHECK_STATUS.ERROR],
      actionType: ({ status }) =>
        ({
          [NIGHTCRAWLER_CHECK_STATUS.WARN]: ActionType.ACTION_RECOMMENDED,
          [NIGHTCRAWLER_CHECK_STATUS.ERROR]: ActionType.ACTION_REQUIRED
        })[status],
      errorMessages: ({ checks }) =>
        onlyFailing(checks).map((c) => {
          switch (c.msg_code) {
            case 'SPFEXP01':
              return 'Value appears before "all" or "redirect" directives.';
            case 'SPFEXP02':
              return "Separator should be '='.";
            case 'SPFEXP03':
              return 'Exp is a invalid domain or macro.';
            case 'SPFEXP04':
              return (
                'Exp macro is invalid: ' +
                +c.msg.replace('Exp macro is valid', '')
              );
          }
        })
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION
    }
  ],
  ptr: [
    {
      status: [NIGHTCRAWLER_CHECK_STATUS.WARN, NIGHTCRAWLER_CHECK_STATUS.ERROR],
      actionType: ({ status }) =>
        ({
          [NIGHTCRAWLER_CHECK_STATUS.WARN]: ActionType.ACTION_RECOMMENDED,
          [NIGHTCRAWLER_CHECK_STATUS.ERROR]: ActionType.ACTION_REQUIRED
        })[status],
      errorMessages: ({ checks }) =>
        onlyFailing(checks).map((c) => {
          switch (c.msg_code) {
            case 'SPFPTR01':
              return 'Invalid domain.';
            case 'SPFPTR02':
              return 'PTR mechanism is expensive and deprecated. Do not use.';
          }
        })
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION,
      errorMessages: ['PTR mechanism is expensive and deprecated. Do not use.']
    }
  ],
  all: [
    {
      default: true,
      required: true,
      actionType: ActionType.ACTION_REQUIRED,
      message: `Not Specified. Expected at End of Record one of '-all', '~all', '?all' or '+all'.`
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.ERROR,
      actionType: ActionType.ACTION_REQUIRED,
      errorMessages: ({ checks }) =>
        onlyFailing(checks).map((c) => {
          switch (c.msg_code) {
            case 'SPFALL01':
              return 'All directive should have no value.';
          }
        })
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION
    }
  ]
});
