<script setup>
import {
  getActionTypeFromRating,
  getActionTypeFromScore
} from '@/Common/Enum/ActionType.js';
import getRating from '@/Common/getRating.js';

const props = defineProps({
  score: Number,
  useRatingToCalculate: {
    type: Boolean,
    default: true
  }
});

const actionType = props.useRatingToCalculate
  ? getActionTypeFromRating(getRating(props.score))
  : getActionTypeFromScore(props.score);

const rating = {
  success: {
    class: 'text-parakeet-500',
    label: 'Low'
  },
  warning: {
    class: 'text-dirty-orange-500',
    label: 'Moderate'
  },
  danger: {
    class: 'text-rio-red-500',
    label: 'High'
  }
}[actionType.color];
</script>

<template>
  <h2 :class="rating.class" class="font-bold">
    {{ rating.label }}
    <slot />
  </h2>
</template>
