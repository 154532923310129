<script setup>
import OutlinedButton from '@/Components/Button/OutlinedButton.vue';

const redirect = () => {
  window.open('https://sendmarc.com/contact/', '_blank');
};
</script>

<template>
  <OutlinedButton size="large" @click="redirect">Contact Us</OutlinedButton>
</template>
