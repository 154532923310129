<script setup>
import Card from '@/Components/Card/Card.vue';
import CardLabel from '@/Components/Card/CardLabel.vue';
import {
  countryCodeEmoji,
  dateFormatterUTC,
  ToggleTracker
} from '@/Common/utils.js';
import IconExpandCircleDown from '@/Components/Icons/IconExpandCircleDown.vue';
import EmailHopDetail from '@/Pages/HeaderAnalysis/Partials/EmailHopDetail.vue';

defineProps({
  hops: Array
});

const toggler = new ToggleTracker();
</script>

<template>
  <div id="hops">
    <CardLabel label="Header Hop Details" />
    <Card>
      <div v-if="0 === hops.length">No Results</div>
      <div v-else class="flex flex-col gap-4">
        <div class="flex items-center gap-6 overflow-x-auto">
          <div class="w-12 font-bold">Hop</div>
          <div class="w-2/12 font-bold">Time</div>
          <div class="w-4/12 grow font-bold">Sender</div>
          <div class="w-4/12 grow font-bold">Receiver</div>
          <div class="w-6"><!-- action col --></div>
        </div>

        <template v-for="(hop, index) in hops" :key="index">
          <div
            class="flex cursor-pointer items-center gap-6"
            @click="toggler.toggle(index)"
          >
            <div class="w-12 flex-none">{{ index + 1 }}</div>

            <div class="flex w-2/12 flex-col">
              <div>
                {{
                  hop.time ? dateFormatterUTC.format(Date.parse(hop.time)) : '-'
                }}
              </div>
              <div v-if="index > 0" class="text-sm">
                ({{ hop.delay_hop }} Second{{ hop.delay_hop !== 1 ? 's' : '' }}
                Delay)
              </div>
            </div>

            <div
              v-for="(item, index_) in [hop.from, hop.by]"
              :key="index_"
              class="flex w-4/12 grow justify-between gap-2"
            >
              <div class="flex flex-col truncate">
                <div class="truncate">{{ item?.name }}</div>
                <div v-if="0 < item?.comments.length" class="truncate text-sm">
                  ({{ item?.comments.join(', ') ?? 'Unknown' }})
                </div>
              </div>
              <div
                v-if="item?.geo?.country_code"
                v-tooltip="item?.geo?.country"
                class="text-3xl"
              >
                {{ countryCodeEmoji(item.geo.country_code) }}
              </div>
            </div>

            <div class="w-6">
              <IconExpandCircleDown
                class="text-navy-200 transition-transform duration-300"
                :class="{ '-rotate-180': toggler.isToggled(index) }"
              />
            </div>
          </div>
          <EmailHopDetail
            v-if="toggler.isToggled(index)"
            :hop="hop"
            class="col-span-5"
          />
        </template>
      </div>
    </Card>
  </div>
</template>
